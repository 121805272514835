import { Navigation } from '@/components/Navigation'
import { StaticImage } from 'gatsby-plugin-image'
import leaf from '@/images/leaf-footer.svg'
import { Contact } from '@/components/Contact'
import { MinimalFooter } from '@/components/MinimalFooter'
import { SEO } from '@/components/Seo'
import { HeadProps } from 'gatsby'

export const Head = (props: HeadProps) => (
  <SEO
    title="Flowers for the big day"
    pathname={props.location.pathname}
    description="Flowers by Primrose can help you put together a floral arrangement for the special day"
  />
)

export default function Weddings() {
  return (
    <div className="relative overflow-hidden bg-white">
      <header className="relative bg-background overflow-hidden">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 bg-background pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-48">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-background lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Navigation />

            <div className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="mt-4 font-serif text-4xl tracking-tight text-gray-900 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span className="block">Funeral flowers</span>
                </h1>
                <p className="mt-3 font-light tracking-tight text-base text-gray-900 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-md lg:mx-0 lg:w-3/4">
                  At Flowers by Primrose, we understand the difficulty of losing
                  a loved one. To help you express your grief, we offer a range
                  of funeral flowers to commemorate them.
                </p>
                <div className="mt-8">
                  <a
                    href="#get-in-touch"
                    className="inline-block rounded-md border border-transparent bg-phathloGreen py-3 px-8 font-bold uppercase tracking-widest text-white hover:ring-2 hover:ring-offset-2 hover:ring-phathloGreen text-xs"
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            className="h-96 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="../images/funeral-wreath.png"
            alt=""
          />
        </div>
      </header>
      <main>
        <Info />
        <Contact text="We understand that losing a loved one is an overwhelming experience. Please reach out to us to discuss your needs" />
      </main>

      <MinimalFooter />
    </div>
  )
}

const Info = () => (
  <div className="bg-white">
    <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <img className="h-16 object-contain mx-auto" src={leaf} alt="" />
        <h2 className="font-serif text-4xl tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
          Sympathy Flowers
        </h2>
        <div className="mx-auto mt-8 max-w-xl font-light text-base text-gray-900 leading-relaxed space-y-6">
          <p>
            Our experienced florists will guide you through the process,
            providing advice and assistance to ensure that the arrangement you
            choose is a fitting and beautiful tribute to your loved one.
          </p>
          <p>
            From wreaths and sprays to handtied sheaths, we can create a bespoke
            arrangement that truly honors the memory of your beloved.
          </p>
          <p>
            We strive to make the process of selecting the right floral
            arrangement as stress-free and manageable as possible.
          </p>
          <p>Please contact us to discuss your needs.</p>
        </div>
      </div>
    </div>
  </div>
)
